import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import logo from '../../assets/NE3_logo.svg';


function Login({ setIsAuthenticated }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        /* try {
            const response = await axios.post('http://localhost:3000/api/auth/login', {
                username,
                password,
            });
    
            if (response.status === 200) {
                sessionStorage.setItem('token', response.data.token);  // Cambiar a sessionStorage
                setIsAuthenticated(true);  // Actualiza el estado de autenticación
                navigate('/');  // Redirige al dashboard o página principal
            } else {
                setError('Usuario o contraseña incorrectos');
            }
        } catch (error) {
            setError('Error en el inicio de sesión: ' + (error.response?.data?.error || 'Error desconocido'));
        }
    };*/

    try {
        const response = await axios.post('https://mapane3.tipicaweb.com/api/api/auth/login', {
            username,
            password,
        });

        if (response.status === 200) {
            sessionStorage.setItem('token', response.data.token);  // Cambiar a sessionStorage
            setIsAuthenticated(true);  // Actualiza el estado de autenticación
            navigate('/');  // Redirige al dashboard o página principal
        } else {
            setError('Usuario o contraseña incorrectos');
        }
    } catch (error) {
        setError('Error en el inicio de sesión: ' + (error.response?.data?.error || 'Error desconocido'));
    }
};
    
    

    return (
        <div className="login-container" style={{ backgroundImage: `url(${logo})`}}>
            <div className="login-form">
                <h2>Iniciar sesión</h2>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Usuario"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Contraseña"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        className="login-button"
                    >
                        Iniciar sesión
                    </Button>
                    {error && <p className="login-error">{error}</p>} {/* Mostrar mensaje de error si existe */}
                </form>
            </div>
        </div>
    );
}

export default Login;
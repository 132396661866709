import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import './mymaps.css';

const BusCheckboxes = ({ selectedElements, handleSelectElement, handleRemoveElement }) => {
  const [busRoutes, setBusRoutes] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  // Cargar todas las rutas de autobuses desde el backend cuando el componente se monte
  useEffect(() => {
    const fetchAllBusRoutes = async () => {
      try {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getLineas`);
        
        // Elimina duplicados basándote en la propiedad "linea"
        const uniqueRoutes = response.data.filter((route, index, self) =>
          index === self.findIndex((r) => r.linea === route.linea)
        );

        setBusRoutes(uniqueRoutes);  // Actualiza busRoutes con rutas únicas
      } catch (error) {
        console.error("Error fetching bus routes:", error);
      }
    };
    fetchAllBusRoutes();
  }, []);

  const fetchBusVariants = async (routeNumber) => {
    try {
      const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getLinea/${routeNumber}`);
      const variants = response.data;

      // Actualiza el estado de busRoutes con las variantes de la ruta correspondiente
      setBusRoutes((prevRoutes) =>
        prevRoutes.map(route =>
          route.linea === routeNumber ? { ...route, variants } : route
        )
      );
      return variants;
    } catch (error) {
      console.error(`Error fetching variants for route ${routeNumber}:`, error);
      return [];
    }
  };

  const handleRouteChange = async (routeNumber) => {
    if (selectedRoutes.includes(routeNumber)) {
      setSelectedRoutes(selectedRoutes.filter(route => route !== routeNumber));
      handleRemoveElement(routeNumber);  // Elimina el polyline
    } else {
      const variants = await fetchBusVariants(routeNumber);
      handleSelectElement(routeNumber, variants, 'bus');  // Agrega la ruta y variantes
      setSelectedRoutes([...selectedRoutes, routeNumber]);
    }
  };

  return (
    <div className="accordion-container">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordion-button">
          <h3>Recorrido de Buses</h3>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="checkbox-container">
            {busRoutes.map(route => (
              <FormControlLabel
                key={route.linea}
                control={
                  <div
                    className={`custom-checkbox ${selectedRoutes.includes(route.linea) ? 'selected' : ''}`}
                    onClick={() => handleRouteChange(route.linea)}
                  >
                    {route.linea}
                  </div>
                }
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BusCheckboxes;
